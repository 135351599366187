<template>
    <div id="config-warehouses" class="container-fluid mb-5 row bg-white p-3">

        <div class="col-12 p-2 bg-lightgray my-3">
            <div class="row py-2 opensans-bold align-items-end">

                <div class="col-3 col-xxl-2 p-0">
                    <ki-input @input="searchWarehouse"
                        v-model="search_query" 
                        label="Buscar" 
                        type="string" 
                        placeholder="Buscar..." 
                        full>
                    </ki-input>
                </div>

                <div class="col-3">
                    <button @click="open_modal = true" 
                            class="btn btn-white pills p-0 px-3" style="height: 40px;">
                            <img :src="icon_add"/>
                            Crear almacén
                    </button>
                </div>

            </div>
        </div>

        <div class="col-12 p-3" v-if="loading">
            <ki-loader></ki-loader>
        </div>

        <div class="col-12" v-if="fetch_error">
            <p>
                Un error ha ocurrido intente más tarde
            </p>
        </div>

        <div class="col-12 p-0 mt-2" v-if="!loading && !fetch_error">
            <ki-table :data="data_table" 
                :allow_headers="headers_allow" 
                no_pagination color_headers="white" color_body="white">
            
                <template v-slot:select="data">
                    <div class="pl-4">
                        <input type="radio" :checked="data.value">
                    </div>
                </template>

                <template v-slot:Editar="data">
                    <button @click="selectWarehouse('edit', data.item.id_dc)" 
                        class="btn btn-white pills p-0"
                        style="width: 50px"
                        type="button">
                        <img :src="icon_write" />
                    </button>
                </template>

                <template v-slot:Borrar="data">
                    <button @click="selectWarehouse('delete', data.item.id_dc)"
                        class="btn btn-white pills p-0"
                        style="width: 50px"
                        type="button">
                        <img :src="icon_delete_red" />
                    </button>
                </template>
            </ki-table>
        </div>

        <!-- Paginator -->
        <div class="col-12 p-0 m-0 mt-2">
            <div class="row justify-content-between align-items-center text-dark">
                <div class="col d-flex">
                    <label class="mr-2">
                        Elementos por página: 
                    </label>
                    <ki-input @keyup.enter="navigate"
                        v-model="rows"
                        :disabled="current_page > 1">
                    </ki-input>
                </div>

                <div class="col-1  d-flex justify-content-end p-0">
                    <button @click.stop="prev"
                        class="btn btn-primary text-white pills"
                        type="button"
                        :disabled="current_page == 1">
                        <b-icon icon="arrow-left"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

                <div class="col-1" style="max-width: 80px;">
                    <ki-input @keyup.enter="navigate"
                        v-model="current_page"
                        full>
                    </ki-input>
                </div>

                <div class="col-1">
                    <p class="m-0">
                        de {{  total_pages  }}
                    </p>
                </div>

                <div class="col-1 p-0" style="max-width: 65px">
                    <button @click.stop="next"
                        class="btn btn-primary text-white pills"
                        :disabled="current_page == total_pages"
                        type="button">
                        <b-icon icon="arrow-right"
                            variant="white">
                        </b-icon>
                    </button>
                </div>

            </div>
        </div>

        <!--create edit warehouse-->
        <ki-modal v-if="open_modal">
            <div class="row bg-white text-dark position-relative" style="border-radius: 15px;">
                <button @click="clearData"
                    class="btn btn-white p-0 position-absolute"
                    style="right: -10px; top:-10px; width: 25px; box-shadow: -5px 5px 10px rgba(0,0,0, 0.3)"
                    type="button">
                    X
                </button>
                <div class="col-12 pt-3 opensans-bold">
                    <p>
                        Registrar nuevo almacén
                    </p>
                </div>
                <div class="col-6">
                    <ki-input v-model="warehouse_name"
                        label="Nombre del Almacén"
                        placeholder="Añadir nombre del almacén..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.name" class="text-danger">
                        {{ errors.name }}
                    </span>
                </div>
                <div class="col-6">
                    <ki-input v-model="warehouse_email"
                        label="Correo electrónico"
                        placeholder="email..."
                        type="email"
                        full>
                    </ki-input>
                    <span v-if="errors.email" class="text-danger">
                        {{ errors.email }}
                    </span>
                </div>
                <div class="col-6">
                    <ki-input v-model="warehouse_manager"
                        label="Gerente"
                        placeholder="Añadir nombre del gerente..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.manager" class="text-danger">
                        {{ errors.manager }}
                    </span>
                </div>
                <div class="col-6">
                    <ki-input v-model="warehouse_street"
                        label="Dirección"
                        placeholder="Dirección del almacén..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.street" class="text-danger">
                        {{ errors.street }}
                    </span>
                </div>
                <div class="col-6">
                    <ki-input v-model="warehouse_country"
                        label="País"
                        placeholder="País del almacén..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.state" class="text-danger">
                        {{ errors.state }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-input v-model="warehouse_phone"
                        label="Teléfono"
                        placeholder="Teléfono del almacén..."
                        type="text"
                        full>
                    </ki-input>
                    <span v-if="errors.phone" class="text-danger">
                        {{ errors.phone }}
                    </span>
                </div>

                <div class="col-6">
                    <ki-select v-model="id_store"
                        label="Sucursal:"
                        :options="store_options"
                        full>
                    </ki-select>
                    <span v-if="errors.store" class="text-danger">
                        {{ errors.store }}
                    </span>
                </div>


                <div class="col-12 p-3 d-flex justify-content-end">
                    <button @click="clearData"
                        class="btn btn-white pills"
                        type="button">
                        Cancelar
                    </button>
                    <button @click="createUpdateWarehouse"
                        class="btn btn-primary pills text-white ml-3"
                        type="button">
                        Guardar
                    </button>
                </div>
            </div>
        </ki-modal>

        <!--Delete warehouse-->
        <ki-modal v-if="show_delete_modal">
            <div class="row bg-white justify-content-center p-4 position-relative" style="border-radius: 15px;">
                <button @click="clearData"
                    class="position-absolute btn btn-white p-0"
                    style="top: -10px; right: -10px; width: 30px; height: 30px; box-shadow: -5px 5px 10px rgba(0,0,0,0.2);"
                    type="button">
                    X
                </button>
                <div class="col-12 text-black text-center">
                    <p>¿Está seguro de borrar este almacén?</p>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <button @click="deleteWarehouse"
                        class="btn btn-danger pills text-white"
                        type="button">
                        Borrar
                    </button>
                    <button @click="clearData"
                        class="btn btn-white pills ml-4"
                        type="button">
                        Cancelar
                    </button>
                </div>
            </div>
        </ki-modal>

    </div>
</template>

<script>
    //Components
    import Titles from 'COMPONENTS/Titles.vue'
    import TitleTabs from 'COMPONENTS/TitleTabs.vue'

    //Icons
    import icon_write from 'ASSETS/icons/icon_write.png';
    import icon_delete_red from 'ASSETS/icons/icon_delete_red.png'; 
    import icon_add from 'ASSETS/icons/plus-blue-icon.png'

    //helpers
    import { mapGetters } from 'vuex';

export default {
    name: 'ki-config-warehouses',
    components: {
        'titles': Titles,
        'title-tabs': TitleTabs,
    },
    data() {
        return {
            title_warehouses: [
                { name: 'Configuración de almacenes', selected: true },
            ],
            titletab_active: null,
            headers_allow: [
                { key: 'dc_name', label: "Almacén" },
	            {key: 'store_name', label: 'Sucursal'},
                {key: 'dc_email', label: 'Correo'},
                {key: 'dc_street', label: 'Calle'},
                {key: 'dc_country', label: 'País'},
                {key: 'dc_phone', label: 'Número'},
                {key: 'dc_manager', label: 'Gerente'},
                { key: 'Editar', label: "Editar", width: '30' }, 
                { key: 'Borrar', label: "Borrar", width: '30' }, 
            ],
            data_table: [],
            icon_write,
            icon_delete_red,
            icon_add,
            open_modal: false,
            loading: true,
            fetch_error: false,
            show_delete_modal: false,
            store_options: [],
            search_options: [
                { id: 1, label: 'Nombre del almacen'},
                { id: 2, label: 'Correo del almacen'},
                { id: 3, label: 'Nombre del gerente del almacén'},
                { id: 4, label: 'Número de teléfono/Ceular del almacén'}
            ],
            search_type: '1',
            warehouse_name: null,
            warehouse_email: null,
            warehouse_manager: null,
            warehouse_phone: null,
            warehouse_country: null,
            warehouse_street: null,
            selected_id: null,
            id_store: null,
            errors: {
                name: null,
                email: null,
                manager: null,
                street: null,
                phone: null,
                country: null,
                store: null
            },
            //paginator
            current_page: 1,
            rows: 10,
            search_query: ''
        };
    },
    computed: {
        ...mapGetters({
            warehouses: 'warehouse/getWarehouses',
            warehouse: 'warehouse/getWarehouse',
            total_pages: 'warehouse/getTotalPages',
            total_rows: 'warehouse/getTotalRows',
            getStores: 'branch/getBranches'
        })
    },
    methods: {

        //helpers
        isEmail (value) {
            let exp_reg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

            if(exp_reg.test(value)) {
                return true
            }
            else {
                return false
            }
        },
        clearData() {
            this.selected_id = null
            this.id_store = null
            this.open_modal = false
            this.warehouse_name = null
            this.warehouse_email = null
            this.warehouse_manager = null
            this.warehouse_phone = null
            this.warehouse_country = null
            this.warehouse_street = null

            for(let error in this.errors){
                this.errors[error] = null
            }
        },
        async selectWarehouse(type, id) {
            switch(type) {
                case 'edit':
                    this.selected_id = id
                    await this.$store.dispatch('warehouse/viewWarehouse', {distributor_id: this.selected_id})
                    this.warehouse_country = this.warehouse.dc_country
                    this.warehouse_email = this.warehouse.dc_email
                    this.warehouse_manager = this.warehouse.dc_manager
                    this.warehouse_name = this.warehouse.dc_name
                    this.warehouse_phone = this.warehouse.dc_phone
                    this.warehouse_street = this.warehouse.dc_street
                    this.id_store = this.warehouse.id_store
                    this.open_modal = true
                    break;
                case 'delete':
                    this.selected_id = id
                    this.show_delete_modal = true
                    break;
            }
        },

        //warehouses CRUD
        async createUpdateWarehouse() {
            for(let error in this.errors) {
                this.errors[error] = null
            }

            let is_complete = true
            
            if(_.isNull(this.warehouse_name) || _.isEmpty(this.warehouse_name)) {
                this.errors.name = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.warehouse_email) || _.isEmpty(this.warehouse_email)) {
                this.errors.email= '*Campo obligatorio'
                is_complete = false
            }

            else if(!this.isEmail(this.warehouse_email)) {
                this.errors.email= 'Correo no valido'
                is_complete = false
            }

            if(_.isNull(this.warehouse_street) || _.isEmpty(this.warehouse_street)) {
                this.errors.street= '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.warehouse_country) || _.isEmpty(this.warehouse_country)) {
                this.errors.country = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.warehouse_manager) || _.isEmpty(this.warehouse_manager)) {
                this.errors.manager = '*Campo obligatorio'
                is_complete = false
            }

            if(_.isNull(this.warehouse_phone) || _.isEmpty(this.warehouse_phone)) {
                this.errors.phone = '*Campo obligatorio'
                is_complete = false
                
            }

            else if(this.warehouse_phone.length !== 10) {
                this.errors.phone = '*El teléfono debe ser de 10 digitos'
                is_complete = false
                
            }

            if(_.isNull(this.id_store)) {
                this.errors.id_store = '*Campo obligatorio'
                is_complete = false
                return 
            }

            const payload = {
                dc_name: this.warehouse_name,
                dc_street: this.warehouse_street,
                dc_country: this.warehouse_country,
                dc_phone: this.warehouse_phone,
                dc_manager: this.warehouse_manager,
                dc_email: this.warehouse_email,
                id_store: this.id_store
            }

            if(is_complete) {
                if(this.selected_id){
                    let data = {
                        body: payload,
                        query: {
                            id_dc: this.selected_id
                        }
                    }

                    await this.$store.dispatch('warehouse/editWarehouse', data)
                    await this.navigate();
                    this.clearData()
                    return
                }

                else {
                    await this.$store.dispatch('warehouse/insertWarehouse', payload)
                    await this.navigate();
                    this.clearData()
                }
            }
        },
        async deleteWarehouse() {
            await this.$store.dispatch('warehouse/deleteWarehouse', { warehouse_id: this.selected_id})
            await this.navigate()
            this.selected_id = null
            this.show_delete_modal = false
        },
        
        async searchWarehouse(){
            await this.navigate();
        },
        //paginator
        async navigate(){
            this.empty_search = false
            this.loading = true

            if(this.current_page > this.total_pages){
                this.current_page = this.total_pages == 0 ? 1 : this.current_page;
            }
	        
	        await this.$store.dispatch('warehouse/listWarehouses', { datatable: true, page: this.current_page, rows: this.rows, search: this.search_query})
            
                    
            if(!this.warehouses) {
                this.empty_search = true;
                return
            }

            else {
                this.data_table = this.warehouses
            }
            
            this.loading = false;
        },
        prev(){
            this.current_page--;
            this.navigate()
        },
        next(){
            this.current_page++;
            this.navigate()
        }
    },
    async mounted(){
        try {

            //list warehouses
            this.navigate();

            //brands options
            await this.$store.dispatch('branch/listBranches', {data_table: false })
            this.store_options = this.getStores.map( store => {
                return { id: store.id_store, label: store.store_name}
            })
            this.fetch_error = false
        }

        catch(e) {
            this.fetch_error = true
            this.loading = true
        }
        
    }
};
</script>

<style lang="scss" scoped>

</style>